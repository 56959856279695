import React from 'react'
import SEO from "../../components/atoms/seo"

import Layout from '../../components/organisms/layout'
import Heading from '../../components/molecules/heading'
import Questionnaire from '../../components/organisms/questionnaire'
import KeyFeatures from '../../components/molecules/key-features'

import steps from "../../data/pages/coatings-2/steps.json"
import verifyInformation from "../../data/pages/coatings-2/verify.json"
import content from "../../data/pages/coatings-2/content.json"

const QuestionnairePage = () => (
  <Layout type='floor' showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title=''
        subTitle=''
        weight='regular'
    />
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      formType="garage-2"
      thankYouChatBot
    />
    <KeyFeatures
      features={[
        {
            title: 'Proprietary Formula',
            image: 'formula.png',
            imageAlt: ''
        },
        {
            title: 'Tough As Nails',
            image: 'strong.png',
            imageAlt: ''
        },
        {
            title: '1-day Turnaround',
            image: 'turnaround.png',
            imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
